/**
 * EgpTransition
 */
class EgpTransition {
  static TRANSITION_STD = 'TRANSITION_STD'
  static TRANSITION_FWD = 'TRANSITION_FWD'
  static TRANSITION_FADE = 'TRANSITION_FADE'
  static TRANSITION_SCALE = 'TRANSITION_SCALE'
}

export default EgpTransition;
