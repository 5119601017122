import { BehaviorSubject } from 'rxjs';
import { apiService } from "./ApiService";

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    const user = currentUserSubject.value;
    if (user) {
      if (user.hasOwnProperty('expiresAt')) {
        const expiresAt = new Date(user.expiresAt);
        if (expiresAt.getTime() < new Date().getTime()) {
          logout();
          window.location.reload(true);
        } else {
          return user;
        }
      }
    }
    return false;
  }
};

/**
 * Fetch user and store user details and jwt token in local storage to keep user logged in between page refreshes
 * @param password
 * @returns {Promise<any>}
 */
function login(password) {
  return apiService.login(password)
    .then(user => {
      localStorage.setItem('currentUser', JSON.stringify(user));
      currentUserSubject.next(user);
      return user;
    });
}

/**
 * Remove user from local storage to log user out
 */
function logout() {
  localStorage.removeItem('currentUser');
  currentUserSubject.next(null);
}
