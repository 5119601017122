import { authenticationService } from './AuthenticationService';


export const apiService = {
  login,
  message,
  complete,
};

/**
 * Fetch user from API
 * @param password
 * @returns {Promise<any>}
 */
function login(password) {
  const opts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({username: 'mcdo', password})
  };
  return fetch(process.env.REACT_APP_API_URL + '/app/login', opts)
    .catch(handleError)
    .then(checkStatus)
    .then(parseJSON);
}


/**
 * Send message via API
 * @param formData
 * @returns {Promise<Response>}
 */
function message(formData) {
  const payload = JSON.stringify(Object.fromEntries(formData));
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      Authorization: `Bearer ${currentUser.token}`
    },
    body: payload
  };
  return fetch(process.env.REACT_APP_API_URL + '/app/message', requestOptions)
    .catch(handleError)
    .then(checkStatus)
    .then(parseJSON);
}

/**
 * Complete upload via API
 * @param id
 * @param message
 * @returns {Promise<Response>}
 */
function complete(id, message) {
  const payload = JSON.stringify({message});
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      Authorization: `Bearer ${currentUser.token}`
    },
    body: payload
  };
  return fetch(process.env.REACT_APP_API_URL + '/app/complete/'+id, requestOptions)
    .catch(handleError)
    .then(checkStatus)
    .then(parseJSON);
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////
// Helper functions
/////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Handle network issues
 */
const handleError = error => {
  error.response = {
    status: 0,
    statusText:
      "Cannot connect. Please make sure you are connected to internet."
  };
  throw error;
};

/**
 * Check API response
 */
const checkStatus = response => {
  if (response.ok) {
    return response;
  }

  if ([401, 403].indexOf(response.status) !== -1) {
    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
    authenticationService.logout();
  }

  return response.json()
    .then(json => {
      // Try to decode a JSON response from api call
      return Promise.reject({
        status: response.status,
        ok: false,
        statusText: response.statusText,
        body: json
      });
    })
    .catch(error => {
      // return API error without JSON data
      return Promise.reject({
        status: response.status,
        ok: false,
        statusText: response.statusText,
        body: response.body
      });
    });
};

/**
 * Handle JSON data or NO-CONTENT (status 204|205)
 */
const parseJSON = response => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
};
